import type { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';

export type OnPreferencesModalServiceSelected = (
  selectedService: Service,
) => Promise<void>;

export const createOnPreferencesModalServiceSelectedAction =
  ({
    getControllerState,
    context,
  }: ActionFactoryParams<
    CalendarState,
    CalendarContext
  >): OnPreferencesModalServiceSelected =>
  async (selectedService) => {
    const { onServiceSelected } = await import(
      '@wix/bookings-services-preferences-modal/controllerActions'
    );
    const [, setState] = getControllerState();
    const { flowAPI } = context;

    onServiceSelected({
      httpClient: flowAPI.httpClient,
      instance: flowAPI.controllerConfig.appParams.instance,
      selectedService,
      getServicesPreferencesModalData: () =>
        getControllerState()[0].servicesPreferencesModalData!,
      setServicesPreferencesModalData: (data) => {
        setState({ servicesPreferencesModalData: data });
      },
    });
  };
